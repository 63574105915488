<template>
    <div class="companion"
         :class="{
             'missions-page': isMissionsPage,
             'raised': isRaised,
         }"
         @mouseenter="showCompanionMessage = true"
         @mouseleave="showCompanionMessage = false">
        <div class="companion-notification">
            <div class="companion-message"
                 :class="{ 'visible' : showCompanionMessage }">
                <div class="companion-message__content">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="36"
                         height="104"
                         viewBox="0 0 36 104">
                        <path id="Rectangle_1_copy_3"
                              data-name="Rectangle 1 copy 3"
                              d="M17,1H36V102l-2.487-.006c-0.128,0-3.982.368-5.786-5.287C22.732,79.075,2.477,18.489,1.9,16.3A2.654,2.654,0,0,1,2,14C4.577,11.2,12.568.823,17,1Z"
                              class="cls-1" />
                        <path id="Rectangle_1_copy_4"
                              data-name="Rectangle 1 copy 4"
                              d="M36,101H34c-0.094,0-3.89-.236-4.827-3.086C24.242,82.386,3.013,15.549,2.9,15.3,2.891,15.3,2.486,14.2,4,13c2.521-2.473,7.306-7.729,10-10a8.267,8.267,0,0,1,3-1H36"
                              class="cls-2" />
                    </svg>
                    <div class="message-content pb-3">
                        <p class="mb-0">
                            <Translate translationKey="airdrop-marketing.companionBubbleText"
                                       :fallback="`Airdrop Season 7 is Now Open! Claim Your Share of the <a href='${airdropUrl}' target='_blank'>$30,000 Prize Pool Now!</a>`"
                                       :replacements="{ link: airdropUrl }"
                                       hasHtmlTags/> 🚀
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div id="companion-notification-image"
             ref="companionRef"
             class="companion-message__image floating-animation">
            <div class="message-container">
                <img loading="lazy"
                     width="90"
                     height="120"
                     class="companion-message__image-base"
                     :src="asset_cdn('assets/missions/floating-bitsiuk.png', 90, 120)"
                     alt="Airdrop Season 7 is Now Open! Claim Your Share of the $30,000 Prize Pool Now!"
                     title="Airdrop Season 7 is Now Open! Claim Your Share of the $30,000 Prize Pool Now!">
                <button type="button"
                        class="close position-relative"
                        :class="{ 'invisible' : hideCloseButton }"
                        @click="closeCompanion">
                    &times;
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, onMounted } from 'vue';
    import { asset_cdn } from '@/js/helpers/helpers';

    let companion: HTMLDivElement | null;
    let headerCompanion: HTMLUListElement | null;
    const showCompanionMessage = ref(false);
    const companionRef = ref(null);
    const hideCloseButton = ref(false);

    defineProps<{
        missionsUrl?: string,
        isMissionsPage?: boolean,
        isRaised?: boolean,
    }>();

    const airdropUrl = 'https://www.bitdegree.org/leaderboard';

    onMounted(() => {
        companion = companionRef.value;
        headerCompanion = document.querySelector('.header-companion');

        return headerCompanion;
    });

    const showCompanion = () => {
        companion?.classList.remove('d-none');
        companion?.classList.add('appear');

        setTimeout(() => {
            hideCloseButton.value = false;
            showCompanionMessage.value = true;
            companion?.classList.remove('appear');
        }, 1100);
    };

    const moveToTop = () => {
        headerCompanion?.classList.remove('d-none');
        headerCompanion?.addEventListener('click', () => {
            headerCompanion?.classList.add('d-none');
            showCompanion();
        });
    };

    const closeCompanion = () => {
        showCompanionMessage.value = false;
        hideCloseButton.value = true;

        setTimeout(() => {
            companion?.classList.add('disappear');
        }, 200);

        setTimeout(() => {
            companion?.classList.remove('disappear');
            companion?.classList.add('d-none');
            moveToTop();
        }, 1000);
    };
</script>

<style scoped lang="scss">
@import "@/sass/partials/z-index";
@import "@/sass/partials/variables";

$companion-breakpoint: 1240px;

.companion {
    position: fixed;
    z-index: $companion-z-index;
    right: 35px;
    bottom: 85px;
    display: none;
    align-items: center;
    justify-content: center;

    @media (min-width: $companion-breakpoint) {
        display: flex;
    }

    &.missions-page {
        bottom: 25px;

        &.raised {
            bottom: 80px;
        }
    }

    .companion-message__image {
        right: 0;
        bottom: 0;
        display: block;
        width: 115px;
        height: 115px;
        cursor: pointer;
    }

    .companion-message__image-base {
        position: absolute;
        z-index: $companion-z-index;
        right: 15px;
        bottom: 0;
        filter: drop-shadow(0 0 10px $cornflower-blue);
    }

    svg {
        position: absolute;
        top: -1px;
        left: 0;
        height: 103px;
    }

    @media screen and (max-width: 600px) {
        bottom: 45px;

        .companion-notification {
            margin-right: 30px;
        }

        #companion-notification-image {
            position: relative;
            width: 70px;
            height: 70px;
            margin-right: -28px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .companion-notification {
        position: relative;
        margin-right: -50px;

        .companion-message {
            position: relative;
            right: 0;
            overflow: hidden;
            width: 0;
            max-width: 600px;
            height: 85px;
            max-height: 85px;
            margin-bottom: 10px;
            opacity: 0;
            transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);

            @media screen and (max-width: 600px) {
                max-width: 305px;
                height: 65px;

                .companion-message__content {
                    .message-content {
                        padding: 5px 70px 5px 8px;

                        p {
                            padding-top: 18px;
                            padding-bottom: 0;
                        }
                    }
                }

                p {
                    font-size: 12px;
                }
            }

            &.visible {
                width: 580px;
                max-width: 100%;
                padding-left: 34px;
                background: transparent !important;
                opacity: 1;

                .message-content {
                    margin-left: 0;
                }
            }

            .companion-message__content {
                background: transparent !important;

                .message-content {
                    overflow: hidden;
                    width: 100%;
                    height: 100px;
                    padding: 10px 66px 10px 15px;
                    background-color: $black;
                    color: $white;
                    font-weight: 500;

                    p {
                        color: $white;
                        font-size: 16px;
                    }

                    a {
                        border-bottom: 1px solid #659aff;
                        color: #659aff;
                        text-decoration: none;

                        &:hover {
                            opacity: 0.9;
                        }
                    }
                }
            }
        }
    }
}

.close {
    top: -5px;
    right: 0;
    color: #c2b5c6;
    font-size: 19px;
    opacity: 0.75;
    transition: all 300ms;

    &:hover {
        color: #c2b5c6 !important;
        opacity: 1 !important;
    }
}

.companion-message__image-base {
    z-index: $companion-z-index;
}

.floating-animation {
    animation: floatAnimation 3s ease-in-out infinite;
}

@keyframes floatAnimation {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}

.appear {
    animation: appear 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

.disappear {
    animation: disappear 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: scale(0.5) translateY(100px);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

@keyframes disappear {
    0% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
    100% {
        opacity: 0;
        transform: scale(0.5) translateY(100px);
    }
}
</style>
